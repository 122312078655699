#model-viewer-cgtrader-demo {
    width: 100%;
    height: 100%;
  }

#model-viewer-demo-example {
  width: 100%;
  height: 200px;
}

#model-viewer-demo-promo {
  border-radius: 0.75rem;
  height: 100%;
  width: 100%;
}
