/* select {
  border: 2px solid #000000;
  line-height: 1.75rem;
  height: 2rem;
} */

.qr-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
