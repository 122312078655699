.text-container {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    flex: 1 1 0%;
    font-size: 16pt;
}
.normal-text {
    font-size: 16pt;
}
.large-bold-text {
    font-size: 18pt; 
    font-weight: bold;
}
.medium, .large {
    color: #CCC;
    display: none;
    font-size: 13pt;
}
#ar-qr-container {
    display: flex;
    flex-direction: row;
    padding: 30px;
}

#instructions {
    margin-bottom: 40px;
}

#description-1 {
    text-align: justify;
    text-justify: inter-word;
    width: 100%;
  }
  
  #description-2 {
    text-align: justify;
    text-justify: inter-word;
    width: 100%;
  }

  #rich-text p {
    text-align: justify;
}

#rich-text ol {
    text-align: justify;
}

#rich-text ul {
    text-align: justify;
}

.instruction-subheader {
    margin-top: 5px;
}

#cover-image {
    height: 250px;
}

#cta-container {
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}
.cta {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    flex: 1 1 0%;
}

#ios-desktop-cta-link {
    text-decoration: none;
    color: #FFF;
}

#ios-desktop-cta-container {
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    margin-top: 30px;
    min-width: 200px;
    max-width: 400px;
}

#ios-desktop-cta-container:hover {
    background-color: #E19900;
    cursor: pointer;
}

#ios-desktop-cta-subheader {
    color: #E19900;
    text-decoration: underline;
    cursor: pointer;
}

.ios-desktop-cta {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    flex: 1 1 0%;
}

.ios-desktop-cta:hover * {
    color: #000000 !important;
}

model-viewer#reveal {
    --poster-color: #262626;
}

/* Adjustments for medium customHeight banner */
@media only screen and (min-height: 121px) {
    #wrapper {
        padding: 15px;
    }
    .medium {
        padding-top: 10px;
        display: block;
    }
}

/* Adjustments for large customHeight banner */
@media only screen and (min-height: 161px) {
    #wrapper {
        padding: 18px;
    }
    .medium {
        padding-top: 15px;
    }
    .large {
        display: block;
    }
}

/* Adjustments for devices with smaller widths */
@media only screen and (max-width: 363px) and (min-height: 121px) {
    #wrapper {
        padding: 2px 10px;
    }
}
@media only screen and (max-width: 414px) {
    div {
        letter-spacing: -1px;
    }
    #cover-image {
        width: 90%;
        height: 90%;
    }
}

.ar-model {
  min-width: 800px;
}
