#monad-banner-wrapper {
    margin: 0px; 
    overflow: hidden;
    font-family: Futura;
    color: #FFF;
    background-color: rgb(38, 38, 38); 
    height: 100vh;
    padding: 2px 15px;
}

.normal-text {
    font-size: 16pt;
}

.large-bold-text {
    font-size: 18pt; 
    font-weight: bold;
}

.medium, .large {
    color: #CCC;
    display: none;
    font-size: 13pt;
}

/* Adjustments for medium customHeight banner */
@media only screen and (min-height: 121px) {
    #monad-banner-wrapper {
        padding: 15px;
    }
    .medium {
        padding-top: 10px;
        display: block;
    }
}

/* Adjustments for large customHeight banner */
@media only screen and (min-height: 161px) {
    #monad-banner-wrapper {
        padding: 18px;
    }
    .medium {
        padding-top: 15px;
    }
    .large {
        display: block;
    }
}

/* Adjustments for devices with smaller widths */
@media only screen and (max-width: 363px and min-height: 121px) {
    #monad-banner-wrapper {
        padding: 2px 10px;
    }
}
@media only screen and (max-width: 414px) {
    div {
        letter-spacing: -1px;
    }
}
