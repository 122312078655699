
.public-qr-container {
    height: 100%;
    width: 100%;
    background-color: #282c34;
}

.qr-layout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

  .polaroid {
      margin-left: 10%;
      margin-right: 10%;
      border-top: 20px solid white;
      border-left: 20px solid white;
      border-right: 20px solid white;
      box-shadow: 20px 20px 20px 20px rgb(0 0 0 / 50%) inset;
  }

  .polaroid-img {
    width: 100%;
  }

  .polaroid-name {
      height: 80px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Reenie Beanie', cursive;
      font-size: 35px;
  }

  .qr-download-button {
      margin-top: 30px;
      margin-bottom: 30px;
  }

  .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .qr-download-button {
    background-color: #D5AF37 !important;
    font-weight: 500 !important;
    color: #000000 !important;
  }

  #model-viewer-qr-link {
    width: 80vw;
    height: 600px;
  }