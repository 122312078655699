.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 125px;
    background-color: #d4af37;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}

.upload-container h1 {
    overflow: hidden;
    padding: 5px;
    text-align: center;
}

.plus-sign {
    height: 50px;
    width: 50px;
}

.drag-left {
    margin-right: 20px;
}

.drag-right {
    margin-left: 20px;
}

.error-message {
    color: red;
}