.content-container {
  margin: 50px;
  width: 90%;
}

.banner-wrapper {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.account-item :hover {
  color: #d4af37 !important;
  font-weight: 600 !important;
}

.account-link {
  color: #282c34;
  text-decoration: none;
  font-weight: 500;
}

.navbar-expand-md {
  flex-wrap: wrap !important;
}

.dropdown-menu {
  background-color: rgb(255, 250, 240) !important;
}

@media (max-width: 767px) {
    .right-side {
        display: none !important;
    }
}

.top-nav {
    margin-right: 10px;
}

.separate {
    justify-content: flex-end;
}

.avatar {
    border-radius: 50%;
    border: 2px solid #000000;
    height: 56px;
}
.avatar-small {
    border-radius: 50%;
    height: 46px;
}

.name-and-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar-link {
  text-decoration: none;
}

.dropdown-user-name {
  font-size: 20px;
  margin-left: 5px;
  color: #282c34;
  text-decoration: none;
  font-weight: 500;
}

.logout-button {
    height: 40px;
    margin-right: 10px;
}

.welcome {
    margin-left: 20px;
    margin-right: 20px;
}

.logo {
    height: 50px;
}

.nav-link {
    color: #fffaf0 !important;
    font-weight: 500 !important;
}

.nav-link:hover {
    color: #F1BE15 !important;
}

.link {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
}

.footer {
    color: #000000;
    border-top: 2px solid #000000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 65px;
}

.footer-link {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

  .wrapper {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-template-rows:auto 1fr;
    grid-auto-flow:dense;
    gap: 0px;
    min-height: 100vh;
  }

  .wrapper > * {
    grid-column: col-start / span 12;
  }

  @media (max-width: 500px) {
    .main-head {
        grid-column: col-start / span 12;
        grid-row: 1;
        background-color: #282c34;
        border-bottom: 2px solid #000000;
      }
    .content {
      grid-column: col-start / span 12;
      grid-row: 2 / 12;
      background-color: #fffaf0;
    }
    .main-footer {
      grid-column: col-start / span 12;
      grid-row: 12 / 12;
      background-color: #fffaf0;
    }
    nav ul {
      flex-direction: column;
    }
    .logo {
      height: 40px;
    }
  }

  @media (max-width: 767px) {
    .navbar-nav {
      display: flex !important;
      justify-content: flex-end !important;
    }
    .nav-item {
      display: flex !important;
      justify-content: flex-end !important;
    }
  }

  @media (min-width: 500px) {
    .main-head {
        grid-column: col-start / span 12;
        grid-row: 1;
        background-color: #282c34;
        border-bottom: 2px solid #000000;
      }
    .content {
      grid-column: col-start / span 12;
      grid-row: 2 / 12;
      background-color: #fffaf0;
    }
    .main-footer {
      grid-column: col-start / span 12;
      grid-row: 12 / 12;
      background-color: #fffaf0;
    }
    nav ul {
      flex-direction: column;
    }
  }

  @media (min-width: 700px) {
    .main-head {
        grid-column: col-start / span 12;
        grid-row: 1;
        background-color: #282c34;
        border-bottom: 2px solid #000000;
      }
    .content {
      grid-column: col-start / span 12;
      grid-row: 2 / 12;
      background-color: #fffaf0;
    }
    .main-footer {
      grid-column: col-start / span 12;
      grid-row: 12 / 12;
      background-color: #fffaf0;
    }
    nav ul {
      flex-direction: column;
    }
  }
