.public-vcard-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #282c34;
}
.vcard-layout-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
  font-weight: 600;
  font-size: 60px;
}

.vcard-title {
  font-style: italic;
  letter-spacing: .5px;
  font-size: 20px;
}

.organization {
  padding-left: 5px;
  font-size: 20px;
  font-weight: 600;
}

.location {
  font-size: 18px;
  font-weight: 500;
}

.work-contact-info {
  margin-top: 10px;
}

.card-name-text {
  font-weight: 600;
  font-size: 16px;
}

.social-icon {
  border-radius: 0px !important;
}

.social-top-row {
  display: flex;
  justify-content: flex-end;
}

.social-bottom-row {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 699px) {

  .public-vcard-container {
    width: 100%;
    height: 100%;
  }

  .vcard-content {
      min-height: 1200px;
      height: 100%;
      width: 100%;
      background-color: #fffaf0;
      border-radius: 20px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      display: flex;
      flex-direction: column;
  }

  .left-side {
    width: 100%;
    background-color: pink;
    display: flex;
    flex-direction: column;
  }

  .other-side {
    height: 200px;
    display: flex;
    flex-direction: column;
  }

  .bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .twitter-icon {
    margin-left: 10px;
  }

  .social-rows {
    display: flex;
    flex-direction: column;
  }

  .social-top-row {
    display: flex;
    justify-content: flex-start;
  }

}

@media (min-width: 700px) {

.vcard-content {
    background-color: #fffaf0;
    border-radius: 20px;
    min-width: 800px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: minmax(100px, auto);
}

.left-side {
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location-and-role {
  margin-left: 100px;
  height: 100%;
}

.everything-else {
  margin-right: 100px;
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
}

.other-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-row {
  margin-left: 100px;
}

.bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 100px;
}

.twitter-icon {
  margin-left: 20px;
}

.vcard-photo {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

}