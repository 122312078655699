.upload-section {
    margin-top: 20px;
    margin-bottom: 20px;
}

.create-vcard-form {
    display: flex;
    flex-direction: column;
    width: 600px;
}

@media (max-width: 500px) {
    .create-vcard-form {
        width: 250px;
    }
}

@media (min-width: 500px) and (max-width: 720px) {
    .create-vcard-form {
        width: 450px;
    }
}
