.countdown-component {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
  }
  
  .details-stats-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  
  .stats-group-1 {
    display: flex;
    flex-direction: row;
    width: 50%;
  }
  
  .stats-group-2 {
    display: flex;
    flex-direction: row;
    width: 50%;
  }

  .countdown-tagline {
      margin-top: 20px;
  }