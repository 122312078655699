#error-screen {
    background-image: url(../../assets/mobiusloading.gif);
    background-position: center; 
    background-size: cover; 
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

#error-screen-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto Mono', monospace;
}
