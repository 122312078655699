.color-picker-group-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.color-picker-group-item .block-picker {
    box-shadow: none !important;
}
