.logged-in-whitelist-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-logged-in-whitelist-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
