.qr-card {
    margin: 20px;
    width: 250px;
}

.qr-list-card-title {
    width: 100%;
    overflow: hidden;
}

.qr-title-span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}