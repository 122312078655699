@import url(https://fonts.googleapis.com/css?family=Roboto:500);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#error-screen {
    background-image: url(/static/media/mobiusloading.121e36b4.gif);
    background-position: center; 
    background-size: cover; 
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

#error-screen-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto Mono', monospace;
}

#model-viewer-cgtrader {
    width: 100%;
    height: 100%;
  }

#model-viewer-cgtrader-demo {
  width: 100%;
  height: 100%;
}

#model-viewer-demo-example {
width: 100%;
height: 200px;
}

#model-viewer-demo-promo {
border-radius: 0.75rem;
height: 100%;
width: 100%;
}

#model-viewer-cgtrader-demo {
    width: 100%;
    height: 100%;
  }

#model-viewer-demo-example {
  width: 100%;
  height: 200px;
}

#model-viewer-demo-promo {
  border-radius: 0.75rem;
  height: 100%;
  width: 100%;
}

.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 125px;
    background-color: #d4af37;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}

.upload-container h1 {
    overflow: hidden;
    padding: 5px;
    text-align: center;
}

.plus-sign {
    height: 50px;
    width: 50px;
}

.drag-left {
    margin-right: 20px;
}

.drag-right {
    margin-left: 20px;
}

.error-message {
    color: red;
}

.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 125px;
    background-color: #d4af37;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}

.upload-container h1 {
    overflow: hidden;
    padding: 5px;
    text-align: center;
}

.plus-sign {
    height: 50px;
    width: 50px;
}

.drag-left {
    margin-right: 20px;
}

.drag-right {
    margin-left: 20px;
}

.error-message {
    color: red;
}
.rich-text-editor {
    background-color: #FFFFFF;
    min-height: 250px;
    border: 1px solid #F1F1F1;
}

.toolbar-editor {
    margin-bottom: 0px !important;
}
@font-face {
    font-family: 'GraphikBlack';
    src: local('GraphikBlack'), url(/static/media/GraphikBlack.09a9e308.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikBlackItalic';
    src: local('GraphikBlackItalic'), url(/static/media/GraphikBlackItalic.8419d140.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikBold';
    src: local('GraphikBold'), url(/static/media/GraphikBold.e78d3d4f.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikExtraLight';
    src: local('GraphikExtraLight'), url(/static/media/GraphikExtralight.02088dd0.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikLight';
    src: local('GraphikLight'), url(/static/media/GraphikLight.532fcbe7.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikMedium';
    src: local('GraphikMedium'), url(/static/media/GraphikMedium.f58d53eb.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Graphik';
    src: local('Graphik'), url(/static/media/GraphikRegular.32974657.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikSemibold';
    src: local('GraphikSemibold'), url(/static/media/GraphikSemibold.7ef1e78a.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikSuper';
    src: local('GraphikSuper'), url(/static/media/GraphikSuper.a317efaa.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikThin';
    src: local('GraphikThin'), url(/static/media/GraphikThin.5c1bd905.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikThinItalic';
    src: local('GraphikThinItalic'), url(/static/media/GraphikThinItalic.e48eca4c.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }


@font-face {
    font-family: 'Produkt';
    src: local('ProduktRegular'), url(/static/media/Produkt-Regular.ee69c925.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  button {
      font-family: "GraphikBlack";
  }
.bad-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
}

.bad-data-content-container {
    margin: 50px;
}
#desktop-container {
    font-family: Futura;
    color: #FFF;
    margin: 0px; 
    background-color: rgb(38, 38, 38); 
    height: 100vh;
    width: 100vw;
    overflow: scroll;
}
#desktop-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
}
#desktop-content-container {
    margin-left: 5%; 
    margin-right: 5%; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    flex: 1 1;
}

#model-viewer-desktop {
    width: 80vw;
    height: 600px;
  }

.text-container {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    flex: 1 1;
    font-size: 16pt;
}
.normal-text {
    font-size: 16pt;
}
.large-bold-text {
    font-size: 18pt; 
    font-weight: bold;
}
.medium, .large {
    color: #CCC;
    display: none;
    font-size: 13pt;
}

#ar-qr-container {
    display: flex;
    flex-direction: row;
    padding: 30px;
}

#description-1 {
  text-align: justify;
  text-justify: inter-word;
  width: 100%;
}

#description-2 {
  text-align: justify;
  text-justify: inter-word;
  width: 100%;
}

#description-3 {
    text-align: justify;
    text-justify: inter-word;
}

#description-4 {
    text-align: justify;
    text-justify: inter-word;
}

#description-5 {
    text-align: justify;
    text-justify: inter-word;
}

.instruction-subheader {
    margin-top: 5px;
}

#desktop-cta-link {
    text-decoration: none;
    color: #FFF;
}

#desktop-cta-container {
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    margin-top: 30px;
    min-width: 200px;
}

#desktop-cta-container:hover {
    background-color: #E19900;
    cursor: pointer;
}

#desktop-cta-subheader {
    color: #E19900;
    text-decoration: underline;
    cursor: pointer;
}

.desktop-cta {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    flex: 1 1;
}

.desktop-cta:hover * {
    color: #000000 !important;
}

model-viewer#reveal {
    --poster-color: #262626;
}
/* Adjustments for medium customHeight banner */
@media only screen and (min-height: 121px) {
    #wrapper {
        padding: 15px;
    }
    .medium {
        padding-top: 10px;
        display: block;
    }
}
/* Adjustments for large customHeight banner */
@media only screen and (min-height: 161px) {
    #wrapper {
        padding: 18px;
    }
    .medium {
        padding-top: 15px;
    }
    .large {
        display: block;
    }
}
/* Adjustments for devices with smaller widths */
@media only screen and (max-width: 363px) and (min-height: 121px) {
    #wrapper {
        padding: 2px 10px;
    }
}
@media only screen and (max-width: 414px) {
    div {
        letter-spacing: -1px;
    }
}

#monad-banner-wrapper {
    margin: 0px; 
    overflow: hidden;
    font-family: Futura;
    color: #FFF;
    background-color: rgb(38, 38, 38); 
    height: 100vh;
    padding: 2px 15px;
}

.normal-text {
    font-size: 16pt;
}

.large-bold-text {
    font-size: 18pt; 
    font-weight: bold;
}

.medium, .large {
    color: #CCC;
    display: none;
    font-size: 13pt;
}

/* Adjustments for medium customHeight banner */
@media only screen and (min-height: 121px) {
    #monad-banner-wrapper {
        padding: 15px;
    }
    .medium {
        padding-top: 10px;
        display: block;
    }
}

/* Adjustments for large customHeight banner */
@media only screen and (min-height: 161px) {
    #monad-banner-wrapper {
        padding: 18px;
    }
    .medium {
        padding-top: 15px;
    }
    .large {
        display: block;
    }
}

/* Adjustments for devices with smaller widths */
@media only screen and (max-width: 363px and min-height: 121px) {
    #monad-banner-wrapper {
        padding: 2px 10px;
    }
}
@media only screen and (max-width: 414px) {
    div {
        letter-spacing: -1px;
    }
}

.text-container {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    flex: 1 1;
    font-size: 16pt;
}
.normal-text {
    font-size: 16pt;
}
.large-bold-text {
    font-size: 18pt; 
    font-weight: bold;
}
.medium, .large {
    color: #CCC;
    display: none;
    font-size: 13pt;
}
#ar-qr-container {
    display: flex;
    flex-direction: row;
    padding: 30px;
}

#description-1 {
    text-align: justify;
    text-justify: inter-word;
    width: 100%;
  }
  
  #description-2 {
    text-align: justify;
    text-justify: inter-word;
    width: 100%;
  }

  #rich-text p {
    text-align: justify;
    }

    #rich-text ol {
        text-align: justify;
    }

    #rich-text ul {
        text-align: justify;
    }

.instruction-subheader {
    margin-top: 5px;
}

#android-cta-link {
    text-decoration: none;
    color: #FFF;
}

#android-cta-container {
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

#android-cta-container:hover {
    background-color: #E19900;
    cursor: pointer;
}

#android-cta-subheader {
    color: #E19900;
    text-decoration: underline;
    cursor: pointer;
}

.android-cta {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    flex: 1 1;
}

.android-cta:hover * {
    color: #000000 !important;
}

model-viewer#reveal {
    --poster-color: #262626;
}

/* Adjustments for medium customHeight banner */
@media only screen and (min-height: 121px) {
    #wrapper {
        padding: 15px;
    }
    .medium {
        padding-top: 10px;
        display: block;
    }
}

/* Adjustments for large customHeight banner */
@media only screen and (min-height: 161px) {
    #wrapper {
        padding: 18px;
    }
    .medium {
        padding-top: 15px;
    }
    .large {
        display: block;
    }
}

/* Adjustments for devices with smaller widths */
@media only screen and (max-width: 363px) and (min-height: 121px) {
    #wrapper {
        padding: 2px 10px;
    }
}
@media only screen and (max-width: 414px) {
    div {
        letter-spacing: -1px;
    }
}

#model-viewer-desktop {
    width: 80vw;
    height: 600px;
  }

.text-container {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    flex: 1 1;
    font-size: 16pt;
}
.normal-text {
    font-size: 16pt;
}
.large-bold-text {
    font-size: 18pt; 
    font-weight: bold;
}
.medium, .large {
    color: #CCC;
    display: none;
    font-size: 13pt;
}

#ar-qr-container {
    display: flex;
    flex-direction: row;
    padding: 30px;
}

#description-1 {
  text-align: justify;
  text-justify: inter-word;
  width: 100%;
}

#description-2 {
  text-align: justify;
  text-justify: inter-word;
  width: 100%;
}

#rich-text-all {
    width: 90vw;
}

#rich-text-desktop {
    overflow-x: scroll;
}

.rich-text p {
    text-align: justify;
}

.rich-text ol {
    text-align: justify;
}

.rich-text ul {
    text-align: justify;
}

.instruction-subheader {
    margin-top: 5px;
}

#desktop-cta-link {
    text-decoration: none;
    color: #FFF;
}

#desktop-cta-container {
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    min-width: 200px;
    max-width: 400px;
}

#desktop-cta-container:hover {
    background-color: #E19900;
    cursor: pointer;
}

#desktop-cta-subheader {
    color: #E19900;
    text-decoration: underline;
    cursor: pointer;
}

.desktop-cta {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    flex: 1 1;
}

.desktop-cta:hover * {
    color: #000000 !important;
}

model-viewer#reveal {
    --poster-color: #262626;
}

/* Adjustments for medium customHeight banner */
@media only screen and (min-height: 121px) {
    #wrapper {
        padding: 15px;
    }
    .medium {
        padding-top: 10px;
        display: block;
    }
}

/* Adjustments for large customHeight banner */
@media only screen and (min-height: 161px) {
    #wrapper {
        padding: 18px;
    }
    .medium {
        padding-top: 15px;
    }
    .large {
        display: block;
    }
}

/* Adjustments for devices with smaller widths */
@media only screen and (max-width: 363px) and (min-height: 121px) {
    #wrapper {
        padding: 2px 10px;
    }
}
@media only screen and (max-width: 414px) {
    div {
        letter-spacing: -1px;
    }
}

.text-container {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    flex: 1 1;
    font-size: 16pt;
}
.normal-text {
    font-size: 16pt;
}
.large-bold-text {
    font-size: 18pt; 
    font-weight: bold;
}
.medium, .large {
    color: #CCC;
    display: none;
    font-size: 13pt;
}
#ar-qr-container {
    display: flex;
    flex-direction: row;
    padding: 30px;
}

#instructions {
    margin-bottom: 40px;
}

#description-1 {
    text-align: justify;
    text-justify: inter-word;
    width: 100%;
  }
  
  #description-2 {
    text-align: justify;
    text-justify: inter-word;
    width: 100%;
  }

  #rich-text p {
    text-align: justify;
}

#rich-text ol {
    text-align: justify;
}

#rich-text ul {
    text-align: justify;
}

.instruction-subheader {
    margin-top: 5px;
}

#cover-image {
    height: 250px;
}

#cta-container {
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}
.cta {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    flex: 1 1;
}

#ios-desktop-cta-link {
    text-decoration: none;
    color: #FFF;
}

#ios-desktop-cta-container {
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    margin-top: 30px;
    min-width: 200px;
    max-width: 400px;
}

#ios-desktop-cta-container:hover {
    background-color: #E19900;
    cursor: pointer;
}

#ios-desktop-cta-subheader {
    color: #E19900;
    text-decoration: underline;
    cursor: pointer;
}

.ios-desktop-cta {
    margin-right: 0px; 
    display: flex; 
    flex-direction: column; 
    flex: 1 1;
}

.ios-desktop-cta:hover * {
    color: #000000 !important;
}

model-viewer#reveal {
    --poster-color: #262626;
}

/* Adjustments for medium customHeight banner */
@media only screen and (min-height: 121px) {
    #wrapper {
        padding: 15px;
    }
    .medium {
        padding-top: 10px;
        display: block;
    }
}

/* Adjustments for large customHeight banner */
@media only screen and (min-height: 161px) {
    #wrapper {
        padding: 18px;
    }
    .medium {
        padding-top: 15px;
    }
    .large {
        display: block;
    }
}

/* Adjustments for devices with smaller widths */
@media only screen and (max-width: 363px) and (min-height: 121px) {
    #wrapper {
        padding: 2px 10px;
    }
}
@media only screen and (max-width: 414px) {
    div {
        letter-spacing: -1px;
    }
    #cover-image {
        width: 90%;
        height: 90%;
    }
}

.ar-model {
  min-width: 800px;
}


.color-picker-group-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.color-picker-group-item .block-picker {
    box-shadow: none !important;
}

.logged-in-whitelist-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-logged-in-whitelist-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.countdown-component {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
  }
  
  .details-stats-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  
  .stats-group-1 {
    display: flex;
    flex-direction: row;
    width: 50%;
  }
  
  .stats-group-2 {
    display: flex;
    flex-direction: row;
    width: 50%;
  }

  .countdown-tagline {
      margin-top: 20px;
  }
  /* footer {
    padding: 20px 10px;
    height: 300px;
  }
  
  footer a {
    color: white;
    margin: 0 10px;
  }
  
  footer a:hover {
    color: #d4af37;
    border-bottom: 2px solid #d4af37;
  } */
  
  /* Classes */
  
  .container {
    margin: 0 auto;
    max-width: 1200px;
    overflow: visible;
  }
  
  .showcase {
    height: 500px;
  }
  
  .showcase p {
    margin-bottom: 30px;
  }
  
  .showcase img {
    box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.2);
  }
  
  .showcase-form {
    padding-left: 7px;
  }

  .ar-backdrop {
    background-color: #000000;
    width: 100%;
  }

  .project-name {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px;
  }

  .project-name h1 {
      letter-spacing: 2px;
      font-weight: 600;
      font-size: 58px;
  }

  .tagline {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.project-details-stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}

.details-stats-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.stats-group-1 {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.stats-group-2 {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.meet-the-team-title {
  padding-bottom: 40px;
}

.long-description {
  margin: 50px;
}

.accent-hr {
  width: 100%;
  display: flex;
  justify-content: center;
}
  
  .grid-item-1,
  .grid-item-2 {
    position: relative;
    top: 50px;
  }
  
  .grid {
    overflow: visible;
    display: grid;
    grid-template-columns: 60% 40%;
  }
  
  
  .showcase input[type='email'] {
    padding: 10px 70px 10px 0;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    padding-left: 6px;
  }
  
  .showcase-form {
    margin: auto;
  }
  
  .little-info {
    font-size: 15px;
    font-family: "poppins", sans-serif;
  
  }
  
  .lower-container {
    /* margin: 120px auto; */
    padding: 0;
    max-width: 1400px;
    text-align: center;
  }
  
  
  .title {
    color: black;
    font-size: 20px;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
  }
  
  .text {
    color: black;
    font-size: 19px;
    width: 100%;
    padding: 10px;
    margin: 0, 20px;
    text-align: justify;
  
  }
  
  .flex {
    display: flex;
    justify-content: space-around;
  }
  
  .stats {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .stat {
    margin: 0 30px;
    padding-bottom: 20px;
    text-align: center;
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }

  /* .roadmap-image-div {
    height: 100%;
    min-height: 150px;
    width: 150px;
} */

.roadmap-image-div {
  height: 100%;
  min-height: 300px;
  width: 250px;
}

.roadmap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.roadmap-item-odd {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.roadmap-item-even {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  
}
  .roadmap-text-container-odd {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }

  .roadmap-text-container-even {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }

  .bio-image-div {
      height: 300px;
      width: 100%;
  }

  .team-member-text {
      margin: 20px;
  }

  .slogan {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 20px;
      text-align: center;
  }

  .reasons-list {
      text-align: left;
      font-size: 20px;
      font-weight: 500;
      margin-top: 40px;
      padding-bottom: 30px;
  }

  .list-container {
      margin-bottom: 10px;
  }

  .promo-cta-button {
      width: 100%;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      font-size: 50px;
      border: none !important;
      display: flex;
      justify-content: center;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .promo-cta-button-text {
      font-size: 40px;
      font-weight: 500;
  }

  .social-media-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 30px;
      margin-bottom: 30px;
  }

  .promo-social-icon {
      margin-left: 10px;
  }

  .promo-footer-logo {
      /* width: 50%;
      height: 50%; */
      height: 80px;
  }

  .promo-footer-logo-container {
    margin-top: 20px;
    color: #FFFFFF;
    font-weight: 500;
    /* background-color: #000000; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 20px;
    padding-left: 5px;
  }

  .white-text {
      margin-top: 20px;
      color: #FFFFFF;
      font-weight: 500;
      background-color: #000000;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding-left: 5px;
  }
  
  .details-container {
      display: flex;
      flex-direction: row;
  }

  .details-left-side {
      width: 33%;
      margin: 20px;
  }

  .details-right-side {
    width: 66%;
    margin-left: 20px;
    margin-right: 20px;
}

.left-side-fact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-side-fact-number {
    display: flex;
    align-items: flex-start;
}

.details-right-side-box {
    border: 2px solid #000000;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-details-box {
    margin-bottom: 50px;
}

  .testimonial {
    color: black;
    padding: 40px;
  }
  
  .testimonial img {
      height: 100%;
      -webkit-clip-path: circle();
              clip-path: circle();
  }
  
  .testimonial h3{
    text-align: center;
  }
  
  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    gap: 40px;
  }
  
  .promo-card {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: -7px -7px 20px rgba(0, 0, 0, 0.2),
                 7px  7px 20px rgba(0, 0, 0, 0.2)
  }
  
  .circle {
      background-color: transparent;
      border:3px solid purple;
      height:90px;
      position: relative;
      top: -30px;
      left: 120px;
      border-radius:50%;
      -moz-border-radius:50%;
      -webkit-border-radius:50%;
      width:90px;
  }

  .rich-text-promo-page {
    max-width: 100%;
    overflow: scroll;
    margin-top: 30px;
  }
  
  /* Tablets and Under */
  @media(max-width: 768px) {
    .grid,
    .grid-3 {
      grid-template-columns: 1fr;
    }
  
    .stats .flex {
      flex-direction: column;
    }
  
    .stats {
      text-align: center;
    }
  
    .image {
      width: 600px;
      margin-left: 80px;
    }
  
    .stat {
      margin-bottom: 40px;
      margin-left: 0px;
      width: 100%;
    }
  
    .circle {
        background-color: transparent;
        border:3px solid purple;
        height:90px;
        position: relative;
        top: -30px;
        left: 270px;
        border-radius:50%;
        -moz-border-radius:50%;
        -webkit-border-radius:50%;
        width:90px;
    }
  
    .showcase {
      height: 500px;
      text-align: center;
    }

    .roadmap-text-container-odd {
      display: flex;
      align-items: center;
      margin-left: 30px;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  
    .roadmap-text-container-even {
      display: flex;
      align-items: center;
      margin-right: 30px;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .rich-text-promo-page {
      max-width: 100%;
      overflow: scroll;
      padding: 10px;
    }
  
  }
  
  /* Mobile devices */
  @media(max-width: 500px) {
    .showcase {
      height: 500px;
      text-align: center;
    }
  
    .little-info {
      font-size: 13px;
    }
  
    .image {
      width: 350px;
      margin-left: 70px;
    }
  
    .stat {
      margin-bottom: 40px;
      margin-left: 0px;
      width: 100%;
    }
  
    .circle {
        background-color: transparent;
        border:3px solid purple;
        height:90px;
        position: relative;
        top: -30px;
        left: 150px;
        border-radius:50%;
        -moz-border-radius:50%;
        -webkit-border-radius:50%;
        width:90px;
    }

    .project-details-stats {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
    }
    
    .details-stats-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .stats-group-1 {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    
    .stats-group-2 {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-top: 20px;
    }

    .roadmap-text-container-odd {
      display: flex;
      align-items: center;
      margin-left: 30px;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  
    .roadmap-text-container-even {
      display: flex;
      align-items: center;
      margin-right: 30px;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .rich-text-promo-page {
      max-width: 100%;
      overflow: scroll;
      padding: 10px;
    }
  }

  #promo-model-viewer {
    height: 600px;
    width: 100%;
    background-color: #000000;
    overflow-x: hidden;
    --poster-color: #000000;
  }

  #ar-button {
    /* background-image: url(../../assets/ic_view_in_ar_new_googblue_48dp.png); */
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 12px 50%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    white-space: nowrap;
    bottom: 132px;
    padding: 0px 16px 0px 40px;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 14px;
    color:#4285f4;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #DADCE0;
  }

  #ar-button:active {
    background-color: #E8EAED;
  }

  #ar-button:focus {
    outline: none;
  }

  #ar-button:focus-visible {
    outline: 1px solid #4285f4;
  }

  @-webkit-keyframes circle {
    from { -webkit-transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
    to   { -webkit-transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
  }

  @keyframes circle {
    from { -webkit-transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
    to   { -webkit-transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
  }

  @-webkit-keyframes elongate {
    from { -webkit-transform: translateX(100px); transform: translateX(100px); }
    to   { -webkit-transform: translateX(-100px); transform: translateX(-100px); }
  }

  @keyframes elongate {
    from { -webkit-transform: translateX(100px); transform: translateX(100px); }
    to   { -webkit-transform: translateX(-100px); transform: translateX(-100px); }
  }

  model-viewer > #ar-prompt {
    position: absolute;
    left: 50%;
    bottom: 175px;
    -webkit-animation: elongate 2s infinite ease-in-out alternate;
            animation: elongate 2s infinite ease-in-out alternate;
    display: none;
  }

  model-viewer[ar-status="session-started"] > #ar-prompt {
    display: block;
  }

  model-viewer > #ar-prompt > img {
    -webkit-animation: circle 4s linear infinite;
            animation: circle 4s linear infinite;
  }

  model-viewer > #ar-failure {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: 175px;
    display: none;
  }

  model-viewer[ar-tracking="not-tracking"] > #ar-failure {
    display: block;
  }

  .slider {
    width: 100%;
    text-align: center;
    overflow: visible;
    position: absolute;
    bottom: 16px;
  }

  .slides {
    display: flex;
    justify-content: center;
    overflow: visible;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .slide {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    margin-right: 10px;
    border-radius: 10px;
    border: none;
    display: flex;
  }

  .slide.selected {
    border: 2px solid #4285f4;
  }

  .slide:focus {
    outline: none;
  }

  .slide:focus-visible {
    outline: 1px solid #4285f4;
  }
  
  .progress-bar.hide {
    visibility: hidden;
    transition: visibility 0.3s ease 0s;
  }
  
  .progress-bar {
    display: block;
    width: 33%;
    height: 10%;
    max-height: 2%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0px);
            transform: translate3d(-50%, -50%, 0px);
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 50%) 0px 3px 10px 3px, rgb(0 0 0 / 60%) 0px 0px 5px 1px;
    border: 1px solid rgba(255, 255, 255, 0.9);
  }

/* select {
  border: 2px solid #000000;
  line-height: 1.75rem;
  height: 2rem;
} */

.qr-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-card {
    margin: 20px;
    width: 250px;
}

.qr-list-card-title {
    width: 100%;
    overflow: hidden;
}

.qr-title-span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.qr-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
}

.content-container {
    margin: 50px;
}

.public-qr-container {
    height: 100%;
    width: 100%;
    background-color: #282c34;
}

.qr-layout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

  .polaroid {
      margin-left: 10%;
      margin-right: 10%;
      border-top: 20px solid white;
      border-left: 20px solid white;
      border-right: 20px solid white;
      box-shadow: 20px 20px 20px 20px rgb(0 0 0 / 50%) inset;
  }

  .polaroid-img {
    width: 100%;
  }

  .polaroid-name {
      height: 80px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Reenie Beanie', cursive;
      font-size: 35px;
  }

  .qr-download-button {
      margin-top: 30px;
      margin-bottom: 30px;
  }

  .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .qr-download-button {
    background-color: #D5AF37 !important;
    font-weight: 500 !important;
    color: #000000 !important;
  }

  #model-viewer-qr-link {
    width: 80vw;
    height: 600px;
  }
  .stripe-loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #payment-form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  
  
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  /* Buttons and links */
  #stripe-submit {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  #stripe-submit:hover {
    -webkit-filter: contrast(115%);
            filter: contrast(115%);
  }
  
  #stripe-submit:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    #payment-form {
      width: 80vw;
      min-width: initial;
    }
  }
  
.transfer-search-container {
    margin-top: 40px;
}

.upload-section {
    margin-top: 20px;
    margin-bottom: 20px;
}

.create-vcard-form {
    display: flex;
    flex-direction: column;
    width: 600px;
}

@media (max-width: 500px) {
    .create-vcard-form {
        width: 250px;
    }
}

@media (min-width: 500px) and (max-width: 720px) {
    .create-vcard-form {
        width: 450px;
    }
}

.public-vcard-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #282c34;
}
.vcard-layout-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
  font-weight: 600;
  font-size: 60px;
}

.vcard-title {
  font-style: italic;
  letter-spacing: .5px;
  font-size: 20px;
}

.organization {
  padding-left: 5px;
  font-size: 20px;
  font-weight: 600;
}

.location {
  font-size: 18px;
  font-weight: 500;
}

.work-contact-info {
  margin-top: 10px;
}

.card-name-text {
  font-weight: 600;
  font-size: 16px;
}

.social-icon {
  border-radius: 0px !important;
}

.social-top-row {
  display: flex;
  justify-content: flex-end;
}

.social-bottom-row {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 699px) {

  .public-vcard-container {
    width: 100%;
    height: 100%;
  }

  .vcard-content {
      min-height: 1200px;
      height: 100%;
      width: 100%;
      background-color: #fffaf0;
      border-radius: 20px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      display: flex;
      flex-direction: column;
  }

  .left-side {
    width: 100%;
    background-color: pink;
    display: flex;
    flex-direction: column;
  }

  .other-side {
    height: 200px;
    display: flex;
    flex-direction: column;
  }

  .bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .twitter-icon {
    margin-left: 10px;
  }

  .social-rows {
    display: flex;
    flex-direction: column;
  }

  .social-top-row {
    display: flex;
    justify-content: flex-start;
  }

}

@media (min-width: 700px) {

.vcard-content {
    background-color: #fffaf0;
    border-radius: 20px;
    min-width: 800px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: minmax(100px, auto);
}

.left-side {
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location-and-role {
  margin-left: 100px;
  height: 100%;
}

.everything-else {
  margin-right: 100px;
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
}

.other-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-row {
  margin-left: 100px;
}

.bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 100px;
}

.twitter-icon {
  margin-left: 20px;
}

.vcard-photo {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

}
.content-container {
  margin: 50px;
  width: 90%;
}

.banner-wrapper {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.account-item :hover {
  color: #d4af37 !important;
  font-weight: 600 !important;
}

.account-link {
  color: #282c34;
  text-decoration: none;
  font-weight: 500;
}

.navbar-expand-md {
  flex-wrap: wrap !important;
}

.dropdown-menu {
  background-color: rgb(255, 250, 240) !important;
}

@media (max-width: 767px) {
    .right-side {
        display: none !important;
    }
}

.top-nav {
    margin-right: 10px;
}

.separate {
    justify-content: flex-end;
}

.avatar {
    border-radius: 50%;
    border: 2px solid #000000;
    height: 56px;
}
.avatar-small {
    border-radius: 50%;
    height: 46px;
}

.name-and-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar-link {
  text-decoration: none;
}

.dropdown-user-name {
  font-size: 20px;
  margin-left: 5px;
  color: #282c34;
  text-decoration: none;
  font-weight: 500;
}

.logout-button {
    height: 40px;
    margin-right: 10px;
}

.welcome {
    margin-left: 20px;
    margin-right: 20px;
}

.logo {
    height: 50px;
}

.nav-link {
    color: #fffaf0 !important;
    font-weight: 500 !important;
}

.nav-link:hover {
    color: #F1BE15 !important;
}

.link {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
}

.footer {
    color: #000000;
    border-top: 2px solid #000000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 65px;
}

.footer-link {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

  .wrapper {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-template-rows:auto 1fr;
    grid-auto-flow:dense;
    grid-gap: 0px;
    gap: 0px;
    min-height: 100vh;
  }

  .wrapper > * {
    grid-column: col-start / span 12;
  }

  @media (max-width: 500px) {
    .main-head {
        grid-column: col-start / span 12;
        grid-row: 1;
        background-color: #282c34;
        border-bottom: 2px solid #000000;
      }
    .content {
      grid-column: col-start / span 12;
      grid-row: 2 / 12;
      background-color: #fffaf0;
    }
    .main-footer {
      grid-column: col-start / span 12;
      grid-row: 12 / 12;
      background-color: #fffaf0;
    }
    nav ul {
      flex-direction: column;
    }
    .logo {
      height: 40px;
    }
  }

  @media (max-width: 767px) {
    .navbar-nav {
      display: flex !important;
      justify-content: flex-end !important;
    }
    .nav-item {
      display: flex !important;
      justify-content: flex-end !important;
    }
  }

  @media (min-width: 500px) {
    .main-head {
        grid-column: col-start / span 12;
        grid-row: 1;
        background-color: #282c34;
        border-bottom: 2px solid #000000;
      }
    .content {
      grid-column: col-start / span 12;
      grid-row: 2 / 12;
      background-color: #fffaf0;
    }
    .main-footer {
      grid-column: col-start / span 12;
      grid-row: 12 / 12;
      background-color: #fffaf0;
    }
    nav ul {
      flex-direction: column;
    }
  }

  @media (min-width: 700px) {
    .main-head {
        grid-column: col-start / span 12;
        grid-row: 1;
        background-color: #282c34;
        border-bottom: 2px solid #000000;
      }
    .content {
      grid-column: col-start / span 12;
      grid-row: 2 / 12;
      background-color: #fffaf0;
    }
    .main-footer {
      grid-column: col-start / span 12;
      grid-row: 12 / 12;
      background-color: #fffaf0;
    }
    nav ul {
      flex-direction: column;
    }
  }




