@font-face {
    font-family: 'GraphikBlack';
    src: local('GraphikBlack'), url(./fonts/Graphik/GraphikBlack.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikBlackItalic';
    src: local('GraphikBlackItalic'), url(./fonts/Graphik/GraphikBlackItalic.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikBold';
    src: local('GraphikBold'), url(./fonts/Graphik/GraphikBold.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikExtraLight';
    src: local('GraphikExtraLight'), url(./fonts/Graphik/GraphikExtralight.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikLight';
    src: local('GraphikLight'), url(./fonts/Graphik/GraphikLight.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikMedium';
    src: local('GraphikMedium'), url(./fonts/Graphik/GraphikMedium.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Graphik';
    src: local('Graphik'), url(./fonts/Graphik/GraphikRegular.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikSemibold';
    src: local('GraphikSemibold'), url(./fonts/Graphik/GraphikSemibold.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikSuper';
    src: local('GraphikSuper'), url(./fonts/Graphik/GraphikSuper.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikThin';
    src: local('GraphikThin'), url(./fonts/Graphik/GraphikThin.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'GraphikThinItalic';
    src: local('GraphikThinItalic'), url(./fonts/Graphik/GraphikThinItalic.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }


@font-face {
    font-family: 'Produkt';
    src: local('ProduktRegular'), url(./fonts/Produkt/Produkt-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  button {
      font-family: "GraphikBlack";
  }